import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`::: {.page title=“Page 1”}
::: {.layoutArea}
::: {.column}
`}<strong parentName="p">{`CrossFit Open Workout 17.2 Rx’d `}</strong>{`\\
Complete as many rounds and reps as possible in`}</p>
    <p>{`12 minutes of: 2 rounds of:`}</p>
    <p>{`50-ft. weighted walking lunge 16 toes-to-bars\\
8 power cleans`}</p>
    <p>{`Then, 2 rounds of:\\
50-ft. weighted walking lunge 16 bar muscle-ups\\
8 power cleans`}</p>
    <p>{`Etc., alternating between toes-to-bars and bar muscle-ups every 2
rounds.`}</p>
    <p>{`M 50-lb. dumbbells F 35-lb. dumbbells`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids today at 9:30am.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you are competing in the Open please view the standards video in
order to know what to expect when being judged.  We will have additional
times to be judged today from 10-12 at The Ville and 12-1:30 at East.
 Also, we will have time on Sunday at the Ville from 10:30-12:30.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</em></strong>{`
:::
:::
:::`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      